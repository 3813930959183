















import { Component, Vue } from 'vue-property-decorator';
import Error from '../../../components/contents/Error.vue';

@Component({
  components: {
    Error,
  },
})
export default class SingupError extends Vue {
  private beforePath: string = '/signup/form/confirm/';

  get error() {
    const $p: any = this.$parent;
    return $p.status;
  }
}
